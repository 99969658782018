<template>
  <div class="search-container">
    <van-search
      v-model="searchVal"
      placeholder="ىزدەيتىن مازمۇندى جازىڭىز"
      @search="onSearch"
    />
    <van-empty v-show="finished && !pages.total && !courses.length" />
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text=""
      :error.sync="error"
      error-text="مازمۇن ىزدەلۋ ۇستىندە، بۇل جەردى باسىڭىز"
      @load="onLoad"
      :immediate-check="false"
      loading-text="ىزدەلۋدە"
    >
      <CourseItem :hots="courses" />
    </van-list>
  </div>
</template>

<script>
import { searchCourse } from '@/api/search'
import CourseItem from '@/components/Course-item'
import { Notify } from 'vant'

export default {
  data () {
    return {
      searchVal: '', // 搜索关键词
      courses: [], // 课程列表数据
      pages: {
        pagenum: 1,
        pagesize: 9,
        total_pages: null,
        total: null,
        filmName: ''
      },
      loading: false, // 上拉加载更多的 loading 状态
      finished: false, // 是否加载结束
      error: false // 是否加载失败
    }
  },
  components: {
    CourseItem
  },
  mounted () {
    document.title = 'ىزدەۋ'
  },
  methods: {
    onSearch () {
      if (!this.searchVal) {
        return Notify({ type: 'danger', message: 'ىزدەيتىن مازمۇندى جازىڭىز' })
      } else {
        this.pages.filmName = this.searchVal.trim()
        this.courses = []
        this.pages.pagenum = 1
        this.onLoad()
      }
    },

    // 监听底部事件
    async onLoad () {
      try {
        // 1. 请求获取数据
        const { data } = await searchCourse(this.pages)

        // 2. 把数据添加到 courses 数组中
        const results = data.data
        this.courses.push(...results)
        this.pages.total = data.meta.total

        // 3. 设置本次加载中 loading 状态结束
        this.loading = false
        const { pagenum, pagesize } = this.pages
        // 4. 判断数据是否加载结束
        if (pagenum * pagesize >= data.meta.total) {
          // 没有数据了，设置加载状态结束，不再触发上拉加载更多了
          this.finished = true
        } else {
          // 更新获取下一页数据的时间戳
          this.pages.pagenum = pagenum + 1
        }
      } catch (err) {
        console.log(err)
        this.loading = false // 关闭 loading 效果
        this.error = true // 开启错误提示
      }
    },

    // 查询课程列表
    async searchCourse () {
      try {
        const res = await searchCourse(this.pages)
        if (res.code === 200 && res.errorCode === 0) { this.courses = res.data.data }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.search-container {
  color: #999;
  /deep/ .van-search {
    direction: rtl;
  }

  /deep/ .van-field__left-icon {
    margin-left: 10px;
    color: #999;
  }

  /deep/ .van-field__control {
    text-align: right;
  }
}
</style>
